import React from "react";
import "./App.css";
import { FaMapMarkerAlt, FaLink, FaGithub, FaBriefcase } from "react-icons/fa";
import Main from "./Main";
import data from "./data/profile.json";


function ProfileHeader() {
  const location = "El Paso, TX";

  return (
    <div>
      <header className="App-header"></header>
      <div className="main-container">
        <div className="bio-content">
          <img
            className="profile-picture"
            src={
              data.user["profile-picture"]
            }
          />
          <div className="bio-container">
            <b className="bio-name">Roberto Sanchez</b>
            <p className="bio">

            </p>
            <div className="bio-links">
              <FaMapMarkerAlt />
              <span>{location}</span>
              <FaLink />
              <span>
                <a href="https://www.linkedin.com/in/roberto-sanchez-388067192">
                  linkedin.com
                </a>
              </span>
              <FaGithub />
              <span>
                <a href="https://github.com/rsanc1103">github.com</a>
              </span>
            </div>
          </div>
          <div className="bio-subtitle">
            Software Developer at Steele Consulting Inc.
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProfileHeader;
