import React from "react";
import "./App.css";
import Main from "./Main";
import ProfileHeader from "./ProfileHeader";
import data from "./data/profile.json";

function App() {
  return (
    <div>
      <ProfileHeader></ProfileHeader>
      <Main></Main>
    </div>
  );
}

export default App;
