import React from "react";
import "./Post.css";
import data from "./data/profile.json";

function Post(props) {
  return (
    <div className="post">
      <div className="profile-image-container">
        <img
          className="post-profile-image"
          src={
            data.user["profile-picture"]
          }
        />
      </div>
      <div className="post-details">
        <span className="post-author">
          <b>{props.name}</b>
        </span>
        <br />
        <span className="post-date">{props.date}</span>
        <p>
          <span>{props.postText}</span>
        </p>
        {props.postLink ? <a href={props.postLink}>{props.postLink}</a> : ""}
        {props.postImageLink ? (
          <div className="imageIncluded">
            <br />
            <img className="post-image" alt="" src={props.postImageLink} />
          </div>
        ) : (
          ""
        )}
      </div>
    </div>
  );
}
export default Post;
