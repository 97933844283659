import React from "react";
import "./App.css";

function SkillsPost(props) {
  return (
    <div className="skill">
      {props.hashtag ? (
        <div className="skillHashtag">#{props.hashtag}</div>
      ) : (
        <div>
          <h3 className="skillTitle">{props.title}</h3>
          <p>{props.skill}</p>
          <p>{props.skill2}</p>
          <p>{props.skill3}</p>
        </div>
      )}
    </div>
  );
}

export default SkillsPost;
