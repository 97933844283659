import React from "react";
import "./App.css";
import Post from "./Post";
import data from "./data/data.json";

function Timeline() {
  return (
    <div className="postsContainer">
      {data.content.map((x) => (
        <Post
          key={x.id}
          name={x.name}
          date={x.date}
          postText={x.postText}
          postLink={x.postLink ?? ""}
          postImageLink={x.postImageLink}
        ></Post>
      ))}
    </div>
  );
}

export default Timeline;
