import React from "react";
import "./App.css";
import Sidebar from "./Sidebar";
import Timeline from "./Timeline";

function Main() {
  return (
    <div className="main">
      <Sidebar></Sidebar>
      <Timeline></Timeline>
    </div>
  );
}

export default Main;
