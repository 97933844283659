import React from "react";
import "./App.css";
import SkillsPost from "./SkillsPost";
import data from "./data/data.json";

function Sidebar() {
  return (
    <div className="sideBar">
      {/* Education boxes */}
      {data.education.map((e) => (
        <SkillsPost
          key={e.degree}
          title="Education"
          skill={e.degree}
          skill2={"Minor in " + e.minor}
          skill3={"Concentration in " + e.concentration}
        ></SkillsPost>
      ))}

      {/* skill boxes */}
      {data.skills.map((x) => (
        <SkillsPost key={x.skill} hashtag={x.skill}></SkillsPost>
      ))}

      {/* Hobbies boxes */}
      {data.hobbies.map((h) => (
        <SkillsPost
          key={h.hobbie}
          title="Hobbies"
          skill={h.hobbie}
        ></SkillsPost>
      ))}
    </div>
  );
}

export default Sidebar;
